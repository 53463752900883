import { Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FirebaseError } from '@angular/fire/app';
import {
  Auth,
  browserLocalPersistence,
  browserSessionPersistence,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  user,
} from '@angular/fire/auth';
import { DocumentReference, Firestore, doc } from '@angular/fire/firestore';
import { setPersistence } from '@firebase/auth';
import { Observable, distinctUntilChanged, of, shareReplay, switchMap } from 'rxjs';

import type { Nullable } from '@epi/types';

import { getRealtimeDocData } from '../../helpers/get-realtime-data';
import type { UserEntity } from '../../profile/models/user';
import { AuthenticationGateway } from './authentication.gateway';

@Injectable({ providedIn: 'root' })
export class AuthenticationService implements AuthenticationGateway {
  readonly #auth = inject(Auth);
  readonly #firestore = inject(Firestore);
  #lastError: Nullable<FirebaseError> = null;
  #userEndpoint: DocumentReference;

  readonly user$: Observable<Nullable<UserEntity>> = user(this.#auth).pipe(
    shareReplay(1),
    distinctUntilChanged((prev, curr) => prev?.uid === curr?.uid),
    switchMap((user) => (user ? this.getUserProfile$(user.uid) : of(null)))
  );

  get userEndpoint() {
    return this.#userEndpoint;
  }

  constructor() {
    this.user$.pipe(takeUntilDestroyed()).subscribe((aUser: UserEntity | null) => {
      console.info({ aUser });
    });
  }

  async signIn(email: string, password: string, isPersistent = false): Promise<void> {
    try {
      const persistence = isPersistent ? browserLocalPersistence : browserSessionPersistence;
      await setPersistence(this.#auth, persistence);
      await signInWithEmailAndPassword(this.#auth, email, password);
    } catch (error) {
      this.setLastError(error as FirebaseError);
      return Promise.reject(this.#lastError);
    }
  }

  async sendPasswordResetEmail(email: string): Promise<void> {
    try {
      await sendPasswordResetEmail(this.#auth, email);
    } catch (error) {
      this.setLastError(error as FirebaseError);
      console.log(error);
      return Promise.resolve();
    }
  }

  async signOut(): Promise<void> {
    try {
      await signOut(this.#auth);
    } catch (error) {
      this.setLastError(error as FirebaseError);
      console.log(error);
      return Promise.resolve();
    }
  }

  private setLastError(error: FirebaseError): void {
    this.#lastError = error;
    console.error(`[${error.code}] ${error.message}`);
  }

  private getUserProfile$(userUid: string): Observable<UserEntity> {
    return getRealtimeDocData<UserEntity>((this.#userEndpoint = doc(this.#firestore, `admins/${userUid}`)));
  }
}
